import React from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import emailIcon from "../../../../assets/Imagess/emaildropdownicon.svg";
import Buttons from "../../../Atoms/Buttons/Buttons";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import * as Yup from "yup";

import {
  useFetchActiveNotifications,
  useSendEmail,
  useSendEmailInterested,
} from "../../../../Hooks/Notification";
import { Formik, Form } from "formik";
import LoaderButton from "../../Loader/LoaderButton";
import { toast } from "react-toastify";
const EmailModal = ({
  handleOnClose,
  title,
  selectedApplicants,
  setSelectedApplicants,
  refetchData,
}) => {
  const { data: emails, refetch: refetchEmails } = useFetchActiveNotifications(
    false,
    4
  );
  const { mutate: sendEmail, isLoading: isSEndingEmail } = useSendEmail();
  const { mutate: sendEmailInterested, isLoading: isSEndingEmailInterested } =
    useSendEmailInterested();

  const formattedEmailOptions = emails?.data?.map(
    ({ notificationId, notificationName }) => ({
      value: notificationId,
      text: notificationName,
    })
  );
  console.log("vsdfvsdfvjd", selectedApplicants);

  const initialValues = {
    notificationId: "",
  };

  const validationSchema = Yup.object().shape({
    notificationId: Yup.string().required("notificationId is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        {
          selectedApplicants.length > 0
            ? sendEmail(
                {
                  noficationId: values.notificationId,
                  applicantId: selectedApplicants?.map((item) => item.id),
                },
                {
                  onSuccess: () => {
                    handleOnClose();
                    setSelectedApplicants([]);
                    refetchData();
                    refetchEmails();
                    toast.success("Email is sent successfully");
                  },
                }
              )
            : sendEmailInterested(
                {
                  noficationId: values.notificationId,
                  applicantId: selectedApplicants,
                },
                {
                  onSuccess: () => {
                    handleOnClose();
                    setSelectedApplicants([]);
                    refetchData();
                    toast.success("Email is sent successfully");
                  },
                }
              );
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal onClose={handleOnClose} width='40%' title={title}>
              <ModalChildrenContainer>
                <div>
                  {/* <h5>Last Email Title Sent</h5>
                  <p>Lorem ipsum Dolor</p> */}
                </div>
                <CustomizedSelect
                  options={formattedEmailOptions}
                  icon={emailIcon}
                  placeholder='Email Title'
                  name='notificationId'
                  value={values.notificationId}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.notificationId}
                  touched={touched.notificationId}
                  notAbsolute
                />
                {isSEndingEmail || isSEndingEmailInterested ? (
                  <LoaderButton />
                ) : (
                  <Buttons
                    text='Send'
                    type='Submit'
                    handleOnClick={handleSubmit}
                  />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailModal;
