import React from "react";
import ReactApexChart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";

const SourceChannels = () => {
  const chartData = {
    series: [
      {
        name: "Value",
        data: [130, 100, 135, 180],
      },
    ],
    options: {
      chart: {
        height: "100%",
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 4,
        colors: ["#0500FA"],
      },
      fill: {
        opacity: 1,
      },
      xaxis: {
        categories: ["AUD Website", "Email", "Ads", "Facebook Ads"],
        labels: {
          style: {
            colors: "#1b224c",
            fontWeight: 400,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#1b224c",
            fontWeight: 400,
            fontSize: "12px",
          },
        },
      },
      grid: {
        borderColor: "#e0e0e0",
        row: {
          colors: ["#F3F8FF", "#FFFFFF"],
          opacity: 1,
        },
      },
      markers: {
        size: 6,
        colors: ["#0500FA"],
        strokeWidth: 0,
        shape: "rect",
        customSVG: {
          offsetX: 0,
          offsetY: 0,
          cssClass: "",
          path: '<rect x="0" y="0" width="15" height="15" />',
        },
        hover: {
          size: 10,
        },
      },
      tooltip: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: "100%",
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: "8px",
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: "8px",
                },
              },
            },
            markers: {
              size: 3,
              hover: {
                size: 6,
              },
            },
          },
        },
      ],
    },
  };

  return (
    <DashboardContainer title='Source from different Channels'>
      <ReactApexChart
        options={chartData?.options}
        series={chartData?.series}
        type='line'
        height='400'
      />
    </DashboardContainer>
  );
};

export default SourceChannels;
