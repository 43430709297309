import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import GeneralBody from "../components/HOC/GeneralBody/GeneralBody";
import { useValidateUser } from "../Hooks/Authentication";
import { API_URL } from "../Constants";
import axios from "axios";

const PrivateRoutes = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const auth = token;

  // const fetchValidation = async () => {
  //   try {
  //     await axios.get(API_URL + "/api/validate", {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });
  //   } catch (error) {
  //     console.error("Error fetching degree data:", error);
  //     localStorage.removeItem("token");
  //   }
  // };
  // useEffect(() => {
  //   fetchValidation();
  // }, [location]);


  return auth ? <Outlet /> : <Navigate to='/' />;
};

export default PrivateRoutes;
