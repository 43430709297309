import React, { useEffect, useState } from "react";
import styles from "./EmailsREceivedContainer.module.css";
import EmailsReceived from "./ReceivedEmail/EmailsReceived";
import ReceivedEmailsList from "./EmailsReceivedList/ReceivedEmailsList";
import MobileRecievedEmail from "./MobileRecievedEmail";
import { useEdges } from "reactflow";
import EmptyText from "../../../Custom/EmptyText";
import WhiteContainer from "../../../../HOC/WrappingContainer/WhiteContainer";
import NoDataComponent from "../../../Custom/NoData/NoDataComponent";

const EmailsReceivedContainer = ({ notifications }) => {
  const [selectedEmail, setSelectedEmail] = useState(
    (notifications && notifications[0]) || {}
  );
  console.log("nitgrthgvue", notifications);
  return (
    <>
      {notifications?.length > 0 ? (
        <>
          <div className={styles["receivedEmail-container"]}>
            <ReceivedEmailsList
              data={notifications}
              selectedEmail={selectedEmail}
              setSelectedEmail={setSelectedEmail}
            />
            <EmailsReceived email={selectedEmail} />
          </div>
          <div className={styles["receivedEmail_mobilecontainer"]}>
            <MobileRecievedEmail data={notifications} />
          </div>{" "}
        </>
      ) : (
        <WhiteContainer white>
          {/* <EmptyText text='Notifications' /> */}
          <NoDataComponent text='Notifications' />
        </WhiteContainer>
      )}
    </>
  );
};

export default EmailsReceivedContainer;
