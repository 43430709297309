import React from "react";
import styles from "./NationalityCard.module.css";
import Flag from "react-world-flags";
import { NationalityCode } from "./NationalityCode.js";

const NationalityCard = ({ data }) => {
  const code = NationalityCode?.find((i) => data?.name == i.country)?.code;

  console.log("cdkncdnf", code);
  return (
    <div className={styles["nationality_container"]}>
      <div className={styles["space"]}></div>
      <div className={styles["text_container"]}>
        <h3>{data?.name}</h3>
        <h4>{data?.number}</h4>
      </div>
      {/* <img src={flag} className={styles["flag"]} alt='Lebanon Flag' /> */}
      <Flag code={code} className={styles["flag"]} alt='Norway Flag' />
    </div>
  );
};

export default NationalityCard;
