import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const ProgramInformation = ({
  applicant,
  applicationData,
  selectedApplication,
}) => {
  return (
    <div className={styles["personal-container"]}>
      <h3>Program Information </h3>
      <div className={styles["personal-container2"]}>
        <TextContainer
          title='Start Your Application'
          data={applicant?.intersted?.applicationStart_Display}
          required={true}
        />
        <TextContainer
          title='Applying As'
          data={selectedApplication?.applyingAsText}
          required={true}
        />
        <TextContainer
          title='Program of Interest'
          data={
            applicant?.applications &&
            selectedApplication?.programOfInterest_Display
          }
          required={true}
        />
        {(selectedApplication?.applyingAs == 1 ||
          selectedApplication?.applyingAs == 5) && (
          <TextContainer
            title='Your Current Place of Study'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.currentPlaceOfStudy
            }
            required={true}
          />
        )}
        <TextContainer
          title='Term'
          data={selectedApplication?.termName}
          required={true}
        />
      </div>
    </div>
  );
};

export default ProgramInformation;
