import React, { useContext } from "react";
import styles from "./ApplicantDemographics.module.css";
import AgeGraph from "./AgeGraph/AgeGraph";
import GenderGraph from "./GenderGraph/GenderGraph";
import LevelOfEducation from "./LevelOfEducation/LevelOfEducation";
import Nationalities from "./Nationalities/Nationalities";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import GeoChart from "./GeoChart/GeoChart";
import { ApplicationContext } from "../../../../Context/DashbaordContext";
const ApplicantDemographics = () => {

  return (
    <WrappingContainer none noScroll>
      <div className={styles["grid_three"]}>
        <AgeGraph />
        <GenderGraph />
        <LevelOfEducation />
      </div>
      <div className={styles["grid_two"]}>
        <GeoChart />
        <Nationalities />
      </div>
    </WrappingContainer>
  );
};

export default ApplicantDemographics;
