import React from "react";
import Chart from "react-apexcharts";
import styles from "./CampaignCharts.module.css";
import NoDataDashboard from "../../../Dashboard/NoData/NoDataDashboard";
const ColumnChart = ({ campaignDetails }) => {
  const leads = campaignDetails?.interesed || [];
  const applicants = campaignDetails?.applicants || [];
  const externalContact = campaignDetails?.externalContacts || [];

  const interestedArray = [
    leads[0]?.statusCount || 0,
    applicants[0]?.statusCount || 0,
    externalContact[0]?.statusCount || 0,
  ];

  const notInterestedArray = [
    leads[1]?.statusCount || 0,
    applicants[1]?.statusCount || 0,
    externalContact[1]?.statusCount || 0,
  ];
  const isAllZero =
    interestedArray.every((val) => val === 0) &&
    notInterestedArray.every((val) => val === 0);
  const options = {
    chart: {
      type: "bar",
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        stroke: {
          width: 0,
        },
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 500,
            },
          },
        },
      },
    },
    xaxis: {
      type: "category",
      categories: ["Leads", "Prospects", "External Audience"],
      color: "#235594",
    },
    legend: {
      position: "bottom",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: false,
    },
    colors: ["#235594", "#7BB1F6"],
    legend: {
      show: true,
    },
  };

  const series = [
    {
      name: "Interested",
      data: interestedArray,
    },
    {
      name: "Not Interested",
      data: notInterestedArray,
    },
  ];

  return (
    <div
      className={styles["chart_cont"]}
      style={{ justifyContent: isAllZero ? "none" : "" }}
    >
      <h2>Reflection of the Applicants</h2>
      {isAllZero ? (
        <NoDataDashboard />
      ) : (
        <Chart options={options} series={series} type='bar' height='300px' />
      )}
    </div>
  );
};

export default ColumnChart;
