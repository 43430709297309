import { useEffect, useState, useRef, useCallback } from "react";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import styles from "./ApplicantPage.module.css";
import applicanttabIcon from "../../../assets/Imagess/applicanticon.svg";
import intresetedtabIcon from "../../../assets/Imagess/interestedicon.svg";
import TableTopHeader from "../../Moecules/TableTopHeader/TableTopHeader";
import StatusModal from "../../Moecules/Settings/SettingsModal/StatusModal";
import EmailModal from "../../Moecules/Settings/SettingsModal/EmailModal";
import * as tableHeader from "../../Moecules/TableHeader/AplicantHeader";
import statusicon from "../../../assets/Imagess/status.svg";
import sendEmailICon from "../../../assets/Imagess/email.svg";
import {
  useExportApplicantsTable,
  useFetchPostApplicantData,
  useFetchPostInterestedData,
} from "../../../Hooks/Applicant";
import { useExportShowingInterestTable } from "../../../Hooks/Interested";
import { useLocation, useNavigate } from "react-router-dom";
import TableComponent from "../../Moecules/TableComponent/TableComponent";
import ColoredButton from "../../Atoms/Buttons/ColoredButtons/ColoredButton";
import ColumnVisibility from "../../Moecules/TableTopHeader/ColumnVisibility";
import ApplicantGroupTab from "../../Atoms/Tabs/ApplicantGroupTab";
import SelectedNumberComponent from "../../Moecules/TableTopHeader/SelectedNumberComponent";
import automaticAssign from "../../../assets/Imagess/Users/UsersAssignment/automaticAssign.svg";
import AssignModal from "../../Moecules/UsersAssignment/AssignModal";
import TableFilter from "../../Moecules/Custom/TableFilter/TableFilter";
import PrintComponent from "../../Moecules/Custom/ExportComponent/PrintComponent";
import { useReactToPrint } from "react-to-print";
const AplicantsPage = () => {
  const navigate = useNavigate();
  const printRef = useRef();

  const location = useLocation();
  const [selected, setSelected] = useState(
    location?.state?.selected || "applicants"
  );
  const [isAssignUser, setIsAssignUser] = useState({
    isAssigned: false,
    assignTitle: "Bulk Assign",
  });
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("");
  const [search, setSearch] = useState("");
  const [filterByField, setFilterByField] = useState({
    filterByField: "",
    filterBy: "",
  });
  const [filterbyFieldIntersted, setFilterByFieldIntersted] = useState({
    filterByField: "",
    filterBy: "",
  });
  const [filteredColumn, setFilteredColumn] = useState([]);
  const [visibleColumn, setVisibleColumn] = useState({
    firstName: true,
    middleName: false,
    lastName: true,
    applicationStart_Display: true,
    fieldOfInterest_Display: true,
    mobile: true,
    email: true,
    createdOn: true,
    statusText: true,
    createdOnDisplay: true,
    userAssign: true,
    emailCount: false,
    notesCount: false,
    sourceOfConnection: false,
  });
  const [isFilter, setIsFilter] = useState(false);
  const {
    data: applicants,
    refetch: refetchApplicants,
    isLoading: isApplicantLoading,
  } = useFetchPostApplicantData({
    pageSize: pageSize,
    pageNumber: page,
    searchBy: search,
    desc: orderDir == "desc" ? false : true,
    sortBy: orderBy,
    selected: selected == "applicants" ? true : false,
    filterColumns: filteredColumn,
  });
  const {
    data: interested,
    refetch: refetchInterested,
    isLoading: isInterestedLoading,
  } = useFetchPostInterestedData({
    pageSize: pageSize,
    pageNumber: page,
    searchBy: search,
    desc: orderDir == "desc" ? false : true,
    sortBy: orderBy,
    selected: selected == "applicants" ? false : true,
    filterColumns: filteredColumn,
  });

  const exportShowingInterest = useExportShowingInterestTable();
  const exportApplicantTable = useExportApplicantsTable();

  const handleExport = async (exportFunction, fileName) => {
    try {
      const data = await exportFunction.mutateAsync();
      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected string.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleExportApplicants = () => {
    handleExport(exportApplicantTable, "ApplicantsTable");
  };

  const handleExportInterested = () => {
    handleExport(exportShowingInterest, "InterestedTable");
  };

  const handleNavigateToSinglePage = (e, id) => {
    e.preventDefault();
    navigate(`/home/applicants/${id}`, {
      state: { isApplicant: selected === "applicants" ? true : false },
    });
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (selected === "applicants") {
      refetchApplicants({
        pageNumber: page,
        pageSize: pageSize,
        orderBy: orderBy,
        orderDir: orderDir,
        filterByField: filterByField.filterByField,
        filterBy: filterByField.filterBy,
      });
    } else {
      refetchInterested();
    }
  }, [
    selected,
    orderBy,
    orderDir,
    page,
    pageSize,
    filterByField,
    filterbyFieldIntersted,
  ]);

  useEffect(() => {
    setSelectedApplicants([]);
    setSearch("");
    setOrderBy("");
    setOrderDir("");
    setPage(1);
    setPageSize(10);
    setVisibleColumn({
      firstName: true,
      lastName: true,
      middleName: false,
      applicationStart_Display: true,
      fieldOfInterest_Display: true,
      mobile: true,
      email: true,
      createdOn: true,
      statusText: true,
      createdOnDisplay: true,
      userAssigned: true,
      sourceOfConnection: false,
    });
    setIsFilter(false);
    setFilteredColumn([]);
  }, [selected]);

  //applicant tab
  const applicantTabContainer = [
    {
      text: "Prospects",
      icon: applicanttabIcon,
      number: applicants?.data?.totalCount,
      value: "applicants",
    },
    {
      text: "Leads",
      icon: intresetedtabIcon,
      number: interested?.data?.totalCount,
      value: "inquires",
    },
  ];

  // change status and email buttons when there is a selected applicants
  const additionalButtons = (
    <>
      <div className={`${styles["number"]}`}>
        {selectedApplicants?.length !== 0 && (
          <SelectedNumberComponent selected={selectedApplicants?.length} />
        )}
      </div>

      {selectedApplicants?.length !== 0 && (
        <>
          <ColoredButton
            title='Change Status'
            blue
            rounded={true}
            icon={statusicon}
            handleOnClick={() => setShowStatusModal(true)}
          />
          <ColoredButton
            title='Send Email'
            red
            rounded={true}
            icon={sendEmailICon}
            handleOnClick={() => setShowEmailModal(true)}
          />
          <ColoredButton
            title='Assign User'
            unfilled
            rounded={true}
            icon={automaticAssign}
            handleOnClick={() =>
              setIsAssignUser((prev) => ({
                ...prev,
                assignTitle: "Assign User",
                isAssigned: true,
              }))
            }
          />
        </>
      )}
    </>
  );

  const rightButtons = (
    <>
      <div>
        {selected === "applicants" && applicants?.data?.totalCount > 999
          ? `${applicants?.data?.totalCount} prospect users`
          : selected === "interested" && interested?.data?.totalCount > 999
          ? `${interested?.data?.totalCount} lead users`
          : null}
      </div>

      <ColumnVisibility
        tableHeader={
          selected === "applicants"
            ? tableHeader.prospectHeader
            : tableHeader.leadTableHeader
        }
        visibleColumn={visibleColumn}
        setVisibleColumn={setVisibleColumn}
      />

      <TableFilter
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        setFilterByField={setFilterByField}
        setFilteredColumn={setFilteredColumn}
      />
    </>
  );

  //table data that includes data refetch isloading and tableheader
  const tableData =
    selected === "applicants"
      ? {
          data: {
            ...applicants?.data,
            items: applicants?.data?.items.map((item) => ({
              ...item,
              id: item.applicantId,
              foreignId: item.applicationId,
            })),
          },
          refetch: refetchApplicants,
          isLoading: isApplicantLoading,
          tableHeader: tableHeader.prospectHeader,
        }
      : {
          data: {
            ...interested?.data,
            items: interested?.data?.items.map((item) => ({
              ...item,
              id: item.studentId,
            })),
          },
          refetch: refetchInterested,
          isLoading: isInterestedLoading,
          tableHeader: tableHeader.leadTableHeader,
        };

     
  return (
    <>
      <WrappingContainer>
        <div>
          <div className={styles["tab-container"]}>
            <ApplicantGroupTab
              tabContainer={applicantTabContainer}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          <div className={styles["table-container"]}>
            <TableTopHeader
              isSearch
              search={search}
              setSearch={setSearch}
              handleExport={
                selected === "applicants"
                  ? handleExportApplicants
                  : handleExportInterested
              }
              rightButtons={rightButtons}
              additionalButtons={additionalButtons}
              selected={selected}
              handlePrint={handlePrint}
            />
            <PrintComponent
              ref={printRef}
              title={
                selected === "applicants" ? "Prospects Table" : "Leads Table"
              }
            >
              {selected === "applicants" ? (
                <TableComponent
                  tableData={tableData}
                  setSelected={setSelectedApplicants}
                  selected={selectedApplicants}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setOrderBy={setOrderBy}
                  setOrderDir={setOrderDir}
                  orderDir={orderDir}
                  orderBy={orderBy}
                  setFilterByField={setFilterByField}
                  filterByField={filterByField}
                  visibleColumn={visibleColumn}
                  handleRowClick={handleNavigateToSinglePage}
                  checkBox={true}
                  pagination={true}
                  columnVisibility={true}
                  isFilter={isFilter}
                  isOrder
                  setFilteredColumn={setFilteredColumn}

                />
              ) : (
                <TableComponent
                  tableData={tableData}
                  setSelected={setSelectedApplicants}
                  selected={selectedApplicants}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setOrderBy={setOrderBy}
                  setOrderDir={setOrderDir}
                  orderDir={orderDir}
                  orderBy={orderBy}
                  setFilterByField={setFilterByFieldIntersted}
                  filterByField={filterByField}
                  visibleColumn={visibleColumn}
                  handleRowClick={handleNavigateToSinglePage}
                  checkBox={true}
                  pagination={true}
                  columnVisibility={true}
                  isFilter={isFilter}
                  isOrder
                  setFilteredColumn={setFilteredColumn}
                />
              )}
            </PrintComponent>
          </div>
        </div>
      </WrappingContainer>
      {showStatusModal && (
        <StatusModal
          handleOnClose={() => setShowStatusModal(false)}
          title='Change Status'
          isApplicant={selected === "applicants" ? true : false}
          selected={selectedApplicants}
          setSelected={setSelectedApplicants}
          refetch={
            selected === "applicants" ? refetchApplicants : refetchInterested
          }
        />
      )}
      {showEmailModal && (
        <EmailModal
          handleOnClose={() => setShowEmailModal(false)}
          title='Send Email'
          selectedApplicants={selectedApplicants}
          setSelectedApplicants={setSelectedApplicants}
          refetchData={
            selected === "applicants" ? refetchApplicants : refetchInterested
          }
        />
      )}
      {isAssignUser.isAssigned && (
        <AssignModal
          handleOnClose={() =>
            setIsAssignUser((prev) => ({ ...prev, isAssigned: false }))
          }
          selected={selectedApplicants}
          setSelected={setSelectedApplicants}
          title={isAssignUser.assignTitle}
          refetch={
            selected == "applicants" ? refetchApplicants : refetchInterested
          }
        />
      )}
    </>
  );
};

export default AplicantsPage;
