import React, { useContext } from "react";
import DashboardContainer from "../../DashboardContainer";
import ApexCharts from "react-apexcharts";
import { useFetchHearAboutUs } from "../../../../../Hooks/Dashboard";
import Loader from "../../../Loader/Loader";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import NoDataDashboard from "../../NoData/NoDataDashboard";

const AboutAudChart = () => {
  const { date, setDate, applicationStart } = useContext(ApplicationContext);
  const { data, isLoading } = useFetchHearAboutUs({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const hearAboutUsData = data?.data || [];

  const series = hearAboutUsData?.map((item) => item.value) || [];
  const labels = hearAboutUsData?.map((item) => item.label) || [];

  const chartOptions = {
    chart: {
      type: "pie",
    },
    labels: labels,
    colors: ["#3E36F4", "#7BB1F6", "#FFAE00", "#F3223C", "#358BF0", "#00A900"],
    legend: {
      position: "right",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 900,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "right",
          },
        },
      },

      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const chartSeries = series;

  return (
    <DashboardContainer
      title='How you heard about AUD?'
      noData={hearAboutUsData?.length === 0}
    >
      {isLoading ? (
        <Loader />
      ) : hearAboutUsData?.length === 0 ? (
        <NoDataDashboard />
      ) : (
        <div style={{ paddingTop: "50px" }}>
          <ApexCharts
            options={chartOptions}
            series={chartSeries}
            type='pie'
            width='500'
          />
        </div>
      )}
    </DashboardContainer>
  );
};

export default AboutAudChart;
