import React, { useEffect, useState } from "react";
import TablePagination from "../Pagination/TablePagination";
import styles from "./TableComponent.module.css";
import checkedicon from "../../../assets/Imagess/tableiconhead.svg";
import checkeAllIcon from "../../../assets/Imagess/plusChecksign.svg";
import leftArrow from "../../../assets/Imagess/downarrow.svg";
import deleteIcon from "../../../assets/Imagess/deleteicon.svg";
import editIcon from "../../../assets/Imagess/Edit (1).svg";
import Loader from "../Loader/Loader";
import CheckBox from "../../Atoms/Inputs/CheckBox";
import { formatFunction } from "./TableFunctions";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import TextInput from "../../Atoms/Inputs/TextInput";
import recruitmentIcon from "../../../assets/Imagess/Users/recruitmentIcon.svg";
import NoDataComponent from "../Custom/NoData/NoDataComponent";

const TableComponent = ({
  editAdd,
  selected,
  setSelected,
  page,
  setPage,
  setOrderBy,
  setOrderDir,
  orderDir,
  pageSize,
  setPageSize,
  visibleColumn,
  columnVisibility,
  handleRowClick,
  tableData,
  handleEditClick,
  handleDelete,
  checkBox,
  pagination,
  additionalIcon = null,
  customizedColumn = null,
  isFilter,
  isOrder,
  setFilterByField,
  noScroll,
  setFilteredColumn = null,
}) => {
  const [rotatedColumn, setRotatedColumn] = useState(null);
  const itemsPerPage = tableData?.data?.pageSize;
  const totalItems = tableData?.data?.totalCount;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [checked, setChecked] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(true);
  const [rowColors, setRowColors] = useState([]);
  const [filterValues, setFilterValues] = useState({});

  const [filterColumns, setFilterColumns] = useState([]);

  const handleFilterBy = (value, item) => {
    setFilterValues((prev) => ({
      ...prev,
      [item.key]: value,
    }));
    
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const capitalizedColumnName = capitalizeFirstLetter(item.key);
    setFilterColumns((prevColumns) => {
      const existingColumnIndex = prevColumns.findIndex(
        (col) =>
          col.columnName === item.key ||
          col.columnName === capitalizeFirstLetter(item.key)
      );

      if (value === "") {
        if (existingColumnIndex !== -1) {
          const updatedColumns = [...prevColumns];
          updatedColumns.splice(existingColumnIndex, 1);
          return updatedColumns;
        }
        return prevColumns;
      }

      if (existingColumnIndex !== -1) {
        const updatedColumns = [...prevColumns];
        updatedColumns[existingColumnIndex] = {
          columnName: capitalizedColumnName,
          columnValue: value,
        };
        return updatedColumns;
      }

      return [
        ...prevColumns,
        { columnName: capitalizedColumnName, columnValue: value },
      ];
    });
  };

  useEffect(() => {
    if (isFilter) {
      const delayDebounceFn = setTimeout(() => {
        if (setFilteredColumn) {
          setFilteredColumn(filterColumns);
        }
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterColumns, filterValues, isFilter]);

  useEffect(() => {
    if (!isFilter) {
      setFilterValues({});
      setFilterColumns([]);
      if (setFilteredColumn) {
        setFilteredColumn([]);
      }
    }
  }, [isFilter]);

  useEffect(() => {
    if (isFilter) {
      if (filterColumns.length === 1 && filterColumns[0].columnValue === "") {
        if (setFilteredColumn) {
          setFilteredColumn([]);
        }
      }
    }
  }, [filterColumns, filterValues, isFilter]);

  const handleCheckboxClick = (e, itemid, foreignId) => {
    setChecked(selected?.some((item) => item.id == itemid));
    e.stopPropagation();
  };

  useEffect(() => {
    if (selected?.length !== 0) {
      setIsAllChecked(false);
    } else {
      setIsAllChecked(true);
    }
  }, [selected]);

  const toggleCheckUser = (itemId, foreignId) => {
    if (checked) {
      setSelected((prevSelectedApplicants) =>
        prevSelectedApplicants.filter((id) => id.id !== itemId)
      );
    } else {
      setSelected((prevSelectedApplicants) => [
        ...prevSelectedApplicants,
        {
          id: itemId,
          foreignId: foreignId,
        },
      ]);
    }
  };

  const orderByFunction = (key, secondKey, index) => {
    console.log("scjnsdbnds", key, secondKey);

    setTimeout(() => {
      const keyToUse = secondKey != undefined ? secondKey : key;
      const nameToUpperCase = keyToUse[0].toUpperCase() + keyToUse.slice(1);
      setOrderBy(nameToUpperCase);
      setRotatedColumn(keyToUse);
      const newOrderDir = orderDir === "asc" ? "desc" : "asc";
      setOrderDir(newOrderDir);

      tableData.refetch !== null &&
        tableData.refetch({
          pageNumber: page,
          orderDir: newOrderDir,
          orderBy: nameToUpperCase,
        });
    }, [500]);
  };

  const checkOrUncheck = () => {
    if (selected.length !== 0) {
      setIsAllChecked(true);
      setChecked((checked) => !checked);
      setSelected([]);
    } else {
      setIsAllChecked(false);
      const allItemsId = tableData?.data?.items?.map((item) => {
        return { id: item.id, foreignId: item.foreignId };
      });
      setSelected(allItemsId);
    }
  };

  useEffect(() => {
    if (isFilter) {
      const delayDebounceFn = setTimeout(() => {
        setFilterByField(filterColumns);
      }, [1000]);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterColumns]);

  const colorArray = [
    "#f3223c99",
    "#2ba62c9c",
    "#235594ba",
    "#ffb2027a",
    "#2cda629c",
    "#235274ba",
    "#fju3227a",
    "#1b224c66",
  ];

  const getRowColor = (duplicate, phoneNumber) => {
    if (duplicate) {
      var phoneColor = rowColors.filter((c) => c.phoneNumber === phoneNumber);
      if (phoneColor.length > 0) {
        return phoneColor[0].color;
      } else {
        let ranColor = colorArray[rowColors.length];
        let x = {
          phoneNumber: phoneNumber,
          color: ranColor,
        };
        setRowColors([...rowColors, x]);
        return ranColor;
      }
    }
  };

  return (
    <>
      <div
        className={styles["container"]}
        style={{ overflowY: noScroll ? "none" : "" }}
      >
        <table className={styles["table-container"]}>
          <thead>
            <tr>
              {checkBox ? (
                <th
                  className={styles["header-cell"]}
                  style={{ cursor: "pointer" }}
                >
                  <Tooltip content={isAllChecked ? "Check All" : "Uncheck All"}>
                    <img
                      src={isAllChecked ? checkeAllIcon : checkedicon}
                      alt=''
                      onClick={checkOrUncheck}
                    />
                  </Tooltip>
                </th>
              ) : (
                ""
              )}
              {tableData.tableHeader &&
                tableData?.tableHeader?.map((item) => {
                  if (columnVisibility) {
                    if (!visibleColumn[item.key]) {
                      return null;
                    }
                  }
                  return (
                    <th
                      className={styles["header-cell"]}
                      key={
                        item.secondKey != undefined ? item.secondKey : item.key
                      }
                    >
                      {item.title}

                      {/* orderDir || isOrder */}
                      {isOrder && !item.secondKey && (
                        <Tooltip content={`Sort by ${item.title}`}>
                          <img
                            src={leftArrow}
                            alt='icon'
                            className={`${styles["arrowIcon"]} ${
                              rotatedColumn === item.key && orderDir === "asc"
                                ? styles["rotate-up"]
                                : rotatedColumn ===
                                    (item.secondKey != undefined
                                      ? item.secondKey
                                      : item.key) && orderDir === "desc"
                                ? styles["rotate-down"]
                                : ""
                            }`}
                            onClick={() =>
                              orderByFunction(item.key, item.secondKey)
                            }
                          />
                        </Tooltip>
                      )}
                    </th>
                  );
                })}
              {customizedColumn ? <th></th> : ""}
              {editAdd ? <th></th> : ""}
            </tr>
          </thead>
          <tbody>
            <tr
              className={`${styles["filter-row"]} ${
                isFilter ? styles["open"] : ""
              }`}
            >
              <td></td>
              {isFilter &&
                tableData.tableHeader &&
                tableData.tableHeader?.map((item, index) => {
                  if (columnVisibility) {
                    if (!visibleColumn[item.key]) {
                      return null;
                    }
                  }
                  return (
                    <td
                      key={
                        item.secondKey != undefined ? item.secondKey : item.key
                      }
                      className={styles["textInput_container"]}
                    >
                      <TextInput
                        placeholder='Search...'
                        height='25px'
                        value={filterValues[item.key] || ""}
                        onChange={(value) => handleFilterBy(value, item)}
                      />
                    </td>
                  );
                })}
            </tr>

            {tableData.isLoading ? (
              <tr>
                <td
                  colSpan={
                    tableData.tableHeader.length +
                    (checkBox ? 1 : 0) +
                    (customizedColumn ? 1 : 0) +
                    (editAdd ? 1 : 0)
                  }
                >
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {tableData.data?.items?.length > 0 ? (
                  <>
                    {tableData.data?.items?.map((item) => {
                      const id = item.foreignId ? item.foreignId : item.id;
                      return (
                        <tr
                          key={id}
                          onClick={
                            handleRowClick
                              ? (e) => handleRowClick(e, item.id)
                              : undefined
                          }
                          style={{
                            cursor: handleRowClick != null ? "pointer" : "",
                          }}
                        >
                          {checkBox && (
                            <td
                              onClick={(e) =>
                                handleCheckboxClick(e, item.id, item.foreignId)
                              }
                              className={styles["tr-checkbox"]}
                            >
                              <div
                                className={
                                  selected?.some((i) => i.id == item.id)
                                    ? styles["leftBorder"]
                                    : ""
                                }
                              >
                                <p></p>
                              </div>
                              <Tooltip
                                content={
                                  selected?.some((i) => i.id == item.id)
                                    ? "Uncheck"
                                    : "Check"
                                }
                                red
                              >
                                <CheckBox
                                  value={selected?.some((i) => i.id == item.id)}
                                  onChange={() => toggleCheckUser(item.id, item.foreignId)}
                                />
                              </Tooltip>
                            </td>
                          )}
                          {tableData.tableHeader?.map((header) => {
                            return (
                              <React.Fragment
                                key={
                                  header.secondKey != undefined
                                    ? header.secondKey
                                    : header.key
                                }
                              >
                                {columnVisibility ? (
                                  visibleColumn[header.key] && (
                                    <td
                                      key={header.key}
                                      style={{
                                        cursor: handleRowClick ? "pointer" : "",
                                      }}
                                    >
                                      {header.flag === "dublicate" ? (
                                        <span
                                          style={{
                                            backgroundColor:
                                              item.repetition === 1
                                                ? getRowColor(true, item.mobile)
                                                : "",
                                          }}
                                          className={styles["dublicate"]}
                                        >
                                          +{item[header.key]}
                                        </span>
                                      ) : (
                                        formatFunction(
                                          header.flag,
                                          item[header.key]
                                        )
                                      )}
                                    </td>
                                  )
                                ) : (
                                  <td>
                                    {header.flag === "recruitment" ? (
                                      <span
                                        className={
                                          styles["recruitment_type_span"]
                                        }
                                      >
                                        {item[header.key]}
                                        {item.isRequirement == true && (
                                          <span
                                            className={
                                              styles["recruitment_type"]
                                            }
                                          >
                                            <img
                                              src={recruitmentIcon}
                                              alt='recruitment icon'
                                            />
                                            <h5>Recruitment</h5>
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      formatFunction(
                                        header.flag,
                                        header.secondKey
                                          ? item[header.key]?.[header.secondKey]
                                          : item[header.key]
                                      )
                                    )}
                                  </td>
                                )}
                              </React.Fragment>
                            );
                          })}
                          {customizedColumn ? (
                            <td>{customizedColumn(item)}</td>
                          ) : (
                            ""
                          )}
                          {editAdd && (
                            <td>
                              <div className={styles["icon-cont-table"]}>
                                {additionalIcon ? additionalIcon(item) : ""}
                                <Tooltip content='Edit'>
                                  <img
                                    src={editIcon}
                                    alt='icon'
                                    onClick={(e) => handleEditClick(e, item)}
                                  />
                                </Tooltip>
                                <Tooltip content='Delete' red>
                                  <img
                                    src={deleteIcon}
                                    alt='icon'
                                    onClick={(e) => handleDelete(e, item)}
                                  />
                                </Tooltip>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={
                        tableData.tableHeader.length +
                        (checkBox ? 1 : 0) +
                        (customizedColumn ? 1 : 0) +
                        (editAdd ? 1 : 0)
                      }
                      style={{ textAlign: "center" }}
                    >
                      <NoDataComponent  table/>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>{" "}
      {pagination && tableData.data?.items?.length > 0 ? (
        <TablePagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          itemsPerPage={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default TableComponent;
