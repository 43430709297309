import React, { useEffect, useState } from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
import FilledUnFilledText from "../../../Atoms/CustomizedAtoms/FilledUnFilledText";

const MailingInfo = ({
  applicant,
  isApplicant,
  applicationData,
  isLoading,
  selectedApplication,
}) => {
  const [addressInfo, setAddressInfo] = useState();

  const checkBolean = (data) => {
    return data == null ? null : data ? "Yes" : "No";
  };
  useEffect(() => {
    if (applicationData?.applicantDetails) {
      setAddressInfo(
        applicant?.addressInfo?.find(
          (item) =>
            item.addressId == applicationData?.applicantDetails[0]?.addressId
        )
      );
    }
  }, [selectedApplication]);

  return (
    <div className={styles["personal-container"]}>
      <h3> Mailing Address</h3>
      <div className={styles["personalsub-container"]}>
        <div className={styles["personal-container2"]}>
          <TextContainer
            title='Address'
            data={addressInfo?.address1}
            required={true}
          />
          <TextContainer
            title='Country'
            data={addressInfo?.country_Display}
            required={true}
          />
          <TextContainer
            title='City/State'
            data={addressInfo?.cityState_Display}
            required={true}
          />
          <TextContainer
            title='P.O. Box'
            data={addressInfo?.pobox}
            required={false}
          />
          <TextContainer
            title='Zip Code'
            data={addressInfo?.zipCode}
            required={false}
          />
        </div>
        {selectedApplication?.startYourApplication == 2 ? (
          <div className={styles["personal-container2"]}>
            <TextContainer
              title='Requires Visa'
              data={checkBolean(
                applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.studentVisa
              )}
              required={false}
            />
            <TextContainer
              title='UAE/GCC National or UAE Resident'
              data={checkBolean(
                applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.uaE_GCC_Resident
              )}
              required={false}
            />
            <TextContainer
              title='Number of Semesters'
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.country_Display
              }
              required={true}
            />
            <TextContainer
              title='On-Campus Housing'
              data={checkBolean(
                applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.onHouseCampus
              )}
              required={false}
            />
            <TextContainer
              title='Middle Eastern Studies Certificate'
              data={checkBolean(
                applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.middleEasternStudies
              )}
              required={false}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MailingInfo;
