import React from "react";
import styles from "./DashboardContainer.module.css";
import ExportDashboardButton from "./ExportButton/ExportDashboardButton";
const DashboardContainer = ({ children, none, title, hidden, noData }) => {

  return (
    <div
      className={styles["pieChart-cont"]}
      style={{
        backgroundColor: none ? "transparent" : "",
        padding: none ? "0px" : "",
        overflow: hidden ? "scroll" : "",
        display: children ? "flex" : "block",
      }}
    >
      {title && (
        <div className={styles["pieChart-subcont"]}>
          <h2>{title}</h2>
          <ExportDashboardButton />
        </div>
      )}
      <div
        style={{
          flex: "1 1 0%",
          overflow: hidden ? "scroll" : "",
          display: noData ? "flex" : "",
          justifyContent: noData ? "center" : "",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default DashboardContainer;
