import React, { useContext } from "react";
import WhiteContainer from "../../../../HOC/WrappingContainer/WhiteContainer";
import styles from "./GenderGraph.module.css";
import Chart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";
import { useFetchGenderGraphData } from "../../../../../Hooks/Dashboard";
import Loader from "../../../Loader/Loader";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import NoDataDashboard from "../../NoData/NoDataDashboard";

const GenderGraph = () => {
  const { date, setDate, applicationStart } = useContext(ApplicationContext);

  const { data, isLoading } = useFetchGenderGraphData({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const genderData = data?.data || [];
  const series = genderData?.map((item) => item.value) || [];
  const labels = genderData?.map((item) => item.label) || [];

  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          size: "50%",
        },
      },
    },
    colors: ["#007FD9", "#F3223C"],
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: "top",
    },
    labels: labels,
  };

  return (
    <DashboardContainer title='Gender' noData={genderData?.length === 0}>
      {isLoading ? (
        <Loader />
      ) : genderData?.length === 0 ? (
        <NoDataDashboard />
      ) : (
        <div>
          <Chart options={options} series={series} type='donut' />
        </div>
      )}
    </DashboardContainer>
  );
};

export default GenderGraph;
