export const prospectHeader = [
  { title: "First Name", key: "firstName" },
  { title: "Middle Name", key: "middleName" },
  { title: "Last Name", key: "lastName" },
  { title: "Program Type", key: "applicationStart_Display" },
  { title: "Field of interest", key: "fieldOfInterest_Display" },
  { title: "Phone", key: "mobile", flag: "mobile" },
  { title: "Email", key: "email" },
  { title: "User Assign", key: "userAssigned" },
  { title: "Date Applied", key: "createdOnDisplay" },
  { title: "Status", key: "statusText", flag: "status" },
  { title: "Notes Count", key: "noteCount" },
  { title: "Email Count", key: "emailCount" },
  { title: "Source Of Connection", key: "sourceOfConnection" },
];

export const leadTableHeader = [
  { title: "First Name", key: "firstName" },
  { title: "Middle Name", key: "middleName" },
  { title: "Last Name", key: "lastName" },
  { title: "Program Type", key: "applicationStart_Display" },
  { title: "Field of interest", key: "fieldOfInterest_Display" },
  { title: "Phone", key: "mobile", flag: "dublicate" },
  { title: "Email", key: "email", flag: "recruitment" },
  { title: "User Assign", key: "userAssigned" },
  { title: "Created On", key: "createdOnDisplay" },
  { title: "Status", key: "statusText", flag: "status" },
  { title: "Notes Count", key: "noteCount" },
  { title: "Email Count", key: "emailCount" },
  { title: "Source Of Connection", key: "sourceOfConnection" },
];
