import { Form, Formik } from "formik";
import React from "react";
import Buttons from "../../Atoms/Buttons/Buttons";
import ModalChildrenContainer from "../../Atoms/Modal/ModalChildrenContainer";
import Modal from "../../Atoms/Modal/Modal";
import * as Yup from "yup";
import CustomizedSelect from "../../Atoms/Inputs/CustomizedSelect";
import userIcon from "../../../assets/Imagess/userVector.svg";
import { useFetchUsers } from "../../../Hooks/Users";
import { useBulkassign } from "../../../Hooks/UserAssignment";
import { toast } from "react-toastify";
import LoaderButton from "../Loader/LoaderButton";
const AssignModal = ({
  handleOnClose,
  title,
  selected,
  setSelected,
  refetch = null,
}) => {
  const {
    data: users,
    refetch: refetchUsers,
    isLoading: isUsersLoading,
  } = useFetchUsers({
    pageSize: 2000,
    pageNumber: 1,
    selected: true,
  });
  const { mutate: bulkAssignUsers, isLoading } = useBulkassign();

  const initialValues = {
    user: "",
  };

  const validationSchema = Yup.object().shape({
    user: Yup.string().required("user is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const valuesToAdd = {
          userId: values.user,
          assignees: selected?.map((item) => item.id),
        };
        console.log('dakdan',valuesToAdd)
        bulkAssignUsers(valuesToAdd, {
          onSuccess: () => {
            toast.success("Assigned successfully");
            handleOnClose();
            setSelected([]);
            if (refetch) {
              refetch();
            }
          },
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal title={title} width='40%' onClose={handleOnClose}>
              <ModalChildrenContainer>
                <CustomizedSelect
                  icon={userIcon}
                  label='Assign to'
                  placeholder='Select User'
                  options={users?.data?.items?.map(({ userId, firstNameEn }) => ({
                    value: userId,
                    text: firstNameEn,
                  }))}
                  name='user'
                  value={values.user}
                  required={true}
                  // text={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.user}
                  touched={touched.user}
                  notAbsolute
                />

                {isLoading ? (
                  <LoaderButton />
                ) : (
                  <Buttons text='Save' handleOnClick={handleSubmit} />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignModal;
