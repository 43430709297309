import styles from "./Document.module.css";
import SearchInput from "../../../Atoms/Inputs/SearchInput";
import ColoredButton from "../../../Atoms/Buttons/ColoredButtons/ColoredButton";
import Cv from "../../../../assets/Imagess/cv.svg";
import diploma from "../../../../assets/Imagess/diploma.svg";
import academic from "../../../../assets/Imagess/academic.svg";
import emiratId from "../../../../assets/Imagess/emirateId.svg";
import familyBook from "../../../../assets/Imagess/familyBook.svg";
import passport from "../../../../assets/Imagess/passport.svg";
import reference from "../../../../assets/Imagess/referenceIcon.svg";
import CheckBox from "../../../Atoms/Inputs/CheckBox";
import moreIcon from "../../../../assets/Imagess/more-vet-svgrepo-com 1.svg";
import MoreButton from "../../Custom/PopOver/MoreButton";
import deleteicon from "../../../../assets/Imagess/deleteicon.svg";
import printIcon from "../../../../assets/Imagess/printIcon.svg";
import downloadIcon from "../../../../assets/Imagess/downloadIcon.svg";
import { useEffect, useState } from "react";
import bulkicon from "../../../../assets/Imagess/downloadDocuments.svg";
import {
  useDownloadAllDocuments,
  useDownloadDocument,
  useDownloadSelectedDocuments,
  useFetchDocumentById,
} from "../../../../Hooks/SingleApplicant";
import { toast } from "react-toastify";
import Buttons from "../../../Atoms/Buttons/Buttons";
import EmptyText from "../../Custom/EmptyText";
import PopOver from "../../Custom/PopOver/PopOver";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import NoDataComponent from "../../Custom/NoData/NoDataComponent";

const Documents = ({ data }) => {
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [documentId, setDocumentId] = useState();
  const downloadDocument = useDownloadDocument();
  const downloadAllDocuments = useDownloadAllDocuments();
  const downloadSelectedDocuments = useDownloadSelectedDocuments();

  const handleOnSelect = (documentId) => {
    if (selected.includes(documentId)) {
      setSelected(selected.filter((id) => id !== documentId));
    } else {
      setSelected([...selected, documentId]);
    }
  };

  const documentImage = (document) => {
    switch (document) {
      case "CV":
        return Cv;
      case "Passport":
        return passport;
      case "Diploma":
        return diploma;
      case "Test":
        return academic;
      case "Emirates Id":
        return emiratId;
      case "Family Book":
        return familyBook;
      case "reference":
        return reference;
    }
  };

  const prospectName =
    data?.intersted?.firstName +
    "." +
    data?.intersted?.middleName +
    "." +
    data?.intersted?.lastName;

  const handleDownload = async (doc) => {
    try {
      const data = await downloadDocument.mutateAsync(doc?.applicantFileId);
      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = doc.fileName;
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success("Document is Downloaded");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDownloadAll = async () => {
    const applicantId = data?.applications?.[0]?.applicantId;
    const applicationId = data?.applications?.[0]?.applicationId;

    try {
      const downloadResult = await downloadAllDocuments.mutateAsync({
        applicantId,
        applicationId,
      });

      const blob = new Blob([downloadResult.data], { type: "application/zip" });
      const contentDisposition = downloadResult.headers["content-disposition"];
      let fileName = `${prospectName}.zip`;
      if (contentDisposition) {
        const match = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (match != null) {
          fileName = match[1].replace(/['"]/g, "");
        }
      }
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.success("Documents are downloaded");
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Failed to download documents");
    }
  };

  const handleDownloadSelectedDocument = async () => {
    const applicantId = data?.applications[0]?.applicantId;
  
    try {
      const downloadResult = await downloadSelectedDocuments.mutateAsync({
        applicantId: applicantId,
        applicantFilesId: selected,
      });
  
      // Ensure the response is of type 'application/zip'
      if (downloadResult && downloadResult.type === "application/zip") {
        const blob = new Blob([downloadResult], { type: "application/zip" });
        const contentDisposition = downloadResult.headers["content-disposition"];
        let fileName = `${prospectName}.zip`;
  
        // Extract filename from content-disposition
        if (contentDisposition) {
          const match = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (match != null) {
            fileName = decodeURIComponent(match[1].replace(/['"]/g, ""));
          }
        }
  
        // Create a link element to download the file
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
  
        toast.success("Documents are downloaded");
      } else {
        // Handle case where the response is not a ZIP file
        const text = await downloadResult.text(); // Read the response as text to check for errors
        const errorResponse = JSON.parse(text);
        console.error("Error downloading file:", errorResponse);
        toast.error(`Failed to download documents: ${errorResponse.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Failed to download documents");
    }
  };
  


  useEffect(() => {
    if (data?.applicantFiles) {
      const filtered = data.applicantFiles.filter((document) => {
        return (
          document.fileType.toLowerCase().includes(search.toLowerCase()) ||
          document.fileName.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredDocuments(filtered);
    }
  }, [data, search]);

  return (
    <WhiteContainer white>
      {" "}
      {filteredDocuments?.length > 0 ? (
        <>
          <div className={styles["documents-headerContainer"]}>
            <SearchInput setSearch={setSearch} search={search} />
            <div>
              {selected.length !== 0 && (
                <>
                  <p className={styles["circle-icon"]}>{selected.length}</p>
                  <h5>Selected documents</h5>
                </>
              )}
              {selected.length > 0 ? (
                <Buttons
                  icon={bulkicon}
                  rounded={true}
                  color='#F3223C'
                  text='Download'
                  handleOnClick={handleDownloadSelectedDocument}
                />
              ) : filteredDocuments?.length > 0 ? (
                <Buttons
                  text='Download All'
                  color='#F3223C'
                  rounded={true}
                  icon={bulkicon}
                  handleOnClick={handleDownloadAll}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles["documents-subContainer"]}>
            {filteredDocuments?.map((document) => (
              <div
                key={document.applicantFileId}
                className={styles["document"]}
                style={{
                  border: selected.includes(document.applicantFileId)
                    ? "2px solid red"
                    : "",
                }}
              >
                <CheckBox
                  value={selected.includes(document.applicantFileId)}
                  onChange={() => handleOnSelect(document.applicantFileId)}
                />
                <img
                  src={documentImage(document.fileType)}
                  alt='cv'
                  className={styles["document-img"]}
                />
                <div className={styles["document-cont"]}>
                  <h4>{document.fileName}</h4>
                  <MoreButton
                    icon={moreIcon}
                    profileImg='profile-img'
                    width='200px'
                    imgWidth='25px'
                    imgHeight='20px'
                    top='1rem'
                    toolTipText='More'
                  >
                    <PopOver
                      stop
                      info={[
                        {
                          title: "Download",
                          icon: downloadIcon,
                          handleFunction: () => handleDownload(document),
                        },
                        // {
                        //   title: "Print",
                        //   icon: printIcon,
                        // },
                        // {
                        //   title: "Delete",
                        //   icon: deleteicon,
                        // },
                      ]}
                    />
                  </MoreButton>
                </div>
                <p>{document.fileType}</p>
              </div>
            ))}
          </div>{" "}
        </>
      ) : (
        // <EmptyText text='Documents' />
        <NoDataComponent text='Documents' />
      )}
    </WhiteContainer>
  );
};

export default Documents;
