import React, { useContext } from "react";
import styles from "./GeoChart.module.css";
import { Chart } from "react-google-charts";
import DashboardContainer from "../../DashboardContainer";
import { useFetchNationalities } from "../../../../../Hooks/Dashboard";
import Loader from "../../../Loader/Loader";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import NoDataDashboard from "../../NoData/NoDataDashboard";

const GeoChart = () => {
  const { date, setDate, applicationStart } = useContext(ApplicationContext);

  const { data: nationalityData, isLoading } = useFetchNationalities({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const nationalities =
    nationalityData?.data?.map((item) => [item.label, item.value]) || [];

  const data = [["Country", "Total"], ...nationalities] || [
    ["Country", "Total"],
  ];

  const options = {
    colorAxis: {
      colors: [
        "#C5D6FF",
        "#A0BCFF",
        "#8CADFF",
        "#779EFF",
        "#5686FF",
        "#3B74FF",
        "#1A5CFF",
      ],
    },
    datalessRegionColor: "##F4F4F4",
    defaultColor: "#F4F4F4",
    legend: "none",
  };
  const isMobile = () => window.innerWidth <= 800;
  return (
    <DashboardContainer
      title='Nationality'
      noData={nationalities?.length === 0}
    >
      {isLoading ? (
        <Loader />
      ) : nationalities?.length === 0 ? (
        <NoDataDashboard />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Chart
            chartType='GeoChart'
            height='400px'
            width={isMobile ? "90%" : "70%"}
            options={options}
            data={data}
          />
        </div>
      )}
    </DashboardContainer>
  );
};

export default GeoChart;
