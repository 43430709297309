import React, { useState } from "react";
import Chart from "react-apexcharts";
import styles from "./StackedColumnGraph.module.css";
import DashboardContainer from "../../DashboardContainer";

const StackedColumnGraph = () => {
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      stacked: false,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: "60px",
      },
    },
    xaxis: {
      categories: [
        "Campaign 1",
        "Campaign 2",
        "Campaign 3",
        "Campaign 4",
  
      ],
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    colors: ["#9FD5F3", "#0078E6"],
  });

  const [series, setSeries] = useState([
    {
      name: "Interested",
      data: [44, 55, 41, 44],
    },
    {
      name: "Converted",
      data: [13, 15, 9, 4],
    },
  ]);
  const additionalWidth =
    options?.xaxis?.categories.length > 9
      ? (options?.xaxis?.categories.length - 9) * 100
      : 0;
  const calculatedWidth = 600 + additionalWidth;

  return (
    <DashboardContainer
      title='Converted to Interest per Campaign'
      hidden={options?.xaxis?.categories.length > 9}
    >
      <div
        className={`${styles["stacked_graph"]} ${
          options?.xaxis?.categories.length > 9
            ? styles["stacked_graph_scrollable"]
            : ""
        } `}
        style={{minWidth:calculatedWidth}}
      >
        <Chart options={options} series={series} type='bar' height={350} />
      </div>
    </DashboardContainer>
  );
};

export default StackedColumnGraph;
