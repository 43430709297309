import React from "react";
import ApexCharts from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";
import "./TimeOfAcceptance.css";
const TimeOfAcceptance = () => {
  const chartOptions = {
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Graduate", "Undergraduate", "Visiting"],
    },
    yaxis: {
      categories: [
        "Rejected",
        "Canceled",
        "Converted",
        "Not Interested",
        "Interested",
      ],
    },
    title: {
      enabled: false,
    },
    plotOptions: {
      heatmap: {
        radius: 50,
        useFillColorAsStroke: false,
        colorScale: {
          width: "12px",
          height: "12px",
          ranges: [
            {
              from: 0,
              to: 10,
              color: "#D3E0FB",
              width: "12px",
              height: "12px",
            },
            {
              from: 10,
              to: 20,
              color: "#6692F1",
              width: "12px",
              height: "12px",
            },
            {
              from: 20,
              to: 40,
              color: "#2463EB",
              width: "15px",
              width: "12px",
              height: "12px",
            },
          ],
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "8px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "8px",
              },
            },
          },
          plotOptions: {
            heatmap: {
              radius: 50,
            },
          },
        },
      },
    ],
  };

  const chartSeries = [
    {
      name: "Rejected",
      data: [
        { x: "Graduate", y: 5 },
        { x: "Undergraduate", y: 35 },
        { x: "Visiting", y: 15 },
      ],
    },
    {
      name: "Canceled",
      data: [
        { x: "Graduate", y: 7 },
        { x: "Undergraduate", y: 2 },
        { x: "Visiting", y: 30 },
      ],
    },
    {
      name: "Converted",
      data: [
        { x: "Graduate", y: 8 },
        { x: "Undergraduate", y: 25 },
        { x: "Visiting", y: 35 },
      ],
    },
    {
      name: "Not Interested",
      data: [
        { x: "Graduate", y: 19 },
        { x: "Undergraduate", y: 16 },
        { x: "Visiting", y: 32 },
      ],
    },
    {
      name: "Interested",
      data: [
        { x: "Graduate", y: 4 },
        { x: "Undergraduate", y: 14 },
        { x: "Visiting", y: 28 },
      ],
    },
  ] || [];

  return (
    <DashboardContainer title='Time of Acceptance'>
      <div className='heatmap-container'>
        <ApexCharts
          options={chartOptions}
          series={chartSeries}
          type='heatmap'
          height={350}
        />
      </div>
    </DashboardContainer>
  );
};

export default TimeOfAcceptance;
