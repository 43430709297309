import React, { useEffect, useRef, useState } from "react";
import styles from "./UserAssignment.module.css";
import WhiteBoxContainer from "../../../HOC/WrappingContainer/WhiteBoxContainer";
import TableTopHeader from "../../TableTopHeader/TableTopHeader";
import * as tableHeader from "../../TableHeader/UsersAssignmentHeader";
import ColumnVisibility from "../../TableTopHeader/ColumnVisibility";
import Filter from "../../TableTopHeader/Filter";
import TableComponent from "../../TableComponent/TableComponent";
import bulkIcon from "../../../../assets/Imagess/Users/UsersAssignment/bulkIcon.svg";
import automaticAssign from "../../../../assets/Imagess/Users/UsersAssignment/automaticAssign.svg";
import SelectedNumberComponent from "../../TableTopHeader/SelectedNumberComponent";
import ColoredButton from "../../../Atoms/Buttons/ColoredButtons/ColoredButton";
import AssignModal from "../AssignModal";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";
import TableFilter from "../../Custom/TableFilter/TableFilter";
import Loader from "../../Loader/Loader";
import PrintComponent from "../../Custom/ExportComponent/PrintComponent";
import { useReactToPrint } from "react-to-print";
import { useExportShowResult } from "../../../../Hooks/UserAssignment";
import AssignUserModal from "../../Users/RolesModal/AssignUserModal";
const UsersAssignment = ({
  usersAssignmentData,
  setOrderBy,
  setOrderDir,
  orderDir,
  orderBy,
  refetch,
  handleShowResult,
  search,
  setSearch,
  isShowingUsrsAssignment,
  setFilterByField,
  isShowResult,
  valuesToExport,
}) => {
  const printRef = useRef();

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isAssignUser, setIsAssignUser] = useState({
    isAssigned: false,
    assignTitle: "",
  });
  const [visibleColumn, setVisibleColumn] = useState({
    firstName: true,
    lastName: true,
    applicationStart_Display: true,
    fieldOfInterest_Display: false,
    mobile: true,
    email: true,
    contactType_Display: false,
    userAssigned: true,
    statusText: false,
    type: false,
    nationality_Display: false,
    howDoYouHear_Display: false,
  });
  const [isFilter, setIsFilter] = useState(false);
  const exportShowResult = useExportShowResult();

  const combinedData = usersAssignmentData
    ? [
        ...(Array.isArray(usersAssignmentData?.applicants)
          ? usersAssignmentData.applicants.map((applicant) => ({
              ...applicant,
              type: "applicant",
              id: applicant.applicantId,
            }))
          : []),
        ...(Array.isArray(usersAssignmentData?.externalContacts)
          ? usersAssignmentData.externalContacts.map((contact) => ({
              ...contact,
              type: "contact",
              id: contact.externalContactId,
              firstName: contact.contactName,
            }))
          : []),
        ...(Array.isArray(usersAssignmentData?.leads)
          ? usersAssignmentData.leads.map((lead) => ({
              ...lead,
              type: "lead",
              id: lead.studentId,
            }))
          : []),
      ]
    : [];

  const tableData = {
    data: {
      items: combinedData.map((item) => ({
        ...item,
        id: item.id,
      })),
    },
    tableHeader: tableHeader.userHeader,
    isLoading: isShowingUsrsAssignment,
    refetch: null,
  };

  useEffect(() => {
    handleShowResult();
  }, [isFilter]);

  const customizedColumn = (item) => (
    <Tooltip content='Assign User'>
      <img
        src={automaticAssign}
        alt='automatic'
        onClick={() => {
          setSelectedStudents((prevSelectedStudents) => [
            ...prevSelectedStudents,
            { id: item.id },
          ]);
          setIsAssignUser((prev) => ({
            ...prev,
            assignTitle: "Assign User",
            isAssigned: true,
          }));
        }}
      />
    </Tooltip>
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleExport = async () => {
    try {
      const data = await exportShowResult.mutateAsync(valuesToExport);

      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "UserAssignmentTable";
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected Blob.", data);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const additionalButtons = (
    <>
      <div className={`${styles["number"]}`}>
        {selectedStudents?.length !== 0 && (
          <SelectedNumberComponent selected={selectedStudents?.length} />
        )}
      </div>
      {selectedStudents?.length !== 0 && (
        <>
          <ColoredButton
            title='Bulk Assign'
            blue
            rounded={true}
            icon={bulkIcon}
            handleOnClick={() =>
              setIsAssignUser((prev) => ({
                ...prev,
                assignTitle: "Bulk Assign",
                isAssigned: true,
              }))
            }
          />
          {selectedStudents.length > 1 ? (
            <ColoredButton
              title='Automatic Assign'
              unfilled
              rounded={true}
              icon={automaticAssign}
              handleOnClick={() =>
                setIsAssignUser((prev) => ({
                  ...prev,
                  assignTitle: "Automatic Assign",
                  isAssigned: true,
                }))
              }
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );

  const rightButtons = (
    <>
      <ColumnVisibility
        visibleColumn={visibleColumn}
        setVisibleColumn={setVisibleColumn}
        tableHeader={tableHeader.userHeader}
      />
      <TableFilter
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        setFilterByField={setFilterByField}
      />
    </>
  );
  console.log("Csajck", selectedStudents);
  return (
    <>
      <WhiteBoxContainer>
        <TableTopHeader
          rightButtons={rightButtons}
          additionalButtons={additionalButtons}
          isSearch
          search={search}
          setSearch={setSearch}
          handlePrint={handlePrint}
          handleExport={handleExport}
        />

        <TableComponent
          tableData={tableData}
          checkBox={true}
          // pagination={true}
          setSelected={setSelectedStudents}
          selected={selectedStudents}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setOrderBy={setOrderBy}
          setOrderDir={setOrderDir}
          orderDir={orderDir}
          orderBy={orderBy}
          customizedColumn={customizedColumn}
          visibleColumn={visibleColumn}
          columnVisibility={true}
          isOrder
          isLoading={isShowingUsrsAssignment}
          isFilter={isFilter}
          setFilterByField={setFilterByField}
        />
      </WhiteBoxContainer>
      {(isAssignUser.assignTitle == "Bulk Assign" ||
        isAssignUser.assignTitle == "Assign User") && (
        <AssignModal
          handleOnClose={() => (
            setIsAssignUser((prev) => ({ ...prev, assignTitle: "" })),
            setSelectedStudents([])
          )}
          title={isAssignUser.assignTitle}
          selected={selectedStudents}
          setSelected={setSelectedStudents}
          refetch={refetch}
        />
      )}
      {isAssignUser.assignTitle == "Automatic Assign" && (
        <AssignUserModal
          handleOnClose={() => (
            setIsAssignUser((prev) => ({ ...prev, assignTitle: "" })),
            setSelectedStudents([])
          )}
          selected={selectedStudents}
          setSelected={setSelectedStudents}
          refetch={refetch}
          title='Automatic Assign'
        />
      )}
      <div style={{ display: "none" }}>
        <PrintComponent
          ref={printRef}
          title='User Assignment'
          data={tableData}
        />
      </div>
    </>
  );
};

export default UsersAssignment;
