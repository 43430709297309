import React, { useState, useRef, useEffect } from "react";
import styles from "./ColoredSelect.module.css";
import redArrow from "../../../../assets/Imagess/Dashboard/redArrow.svg";

const ColoredSelect = ({ options, icon, label, onChange, initialLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const dropdownRef = useRef();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelected(option);
    setIsOpen(false);
    if (onChange) {
      onChange(option);
      console.log("cdsckjdn", option);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles["colored_select_container"]}>
      <div
        className={styles["colored_select_subcontainer"]}
        onClick={toggleDropdown}
      >
        {icon && <img src={icon} alt='icon' />}
        <h2>{selected ? selected.label : initialLabel || label}</h2>
        <img
          src={redArrow}
          alt='arrow'
          className={`${styles["arrow_icon"]} ${
            isOpen ? styles["openedArrow"] : ""
          }`}
        />
      </div>

      {isOpen && (
        <>
          <div
            className={styles["backdrop"]}
            onClick={() => setIsOpen(false)}
          ></div>

          <div ref={dropdownRef} className={styles["dropdown_menu"]}>
            {options?.map((option, index) => (
              <div
                key={index}
                className={
                  index % 2 === 0 ? styles["even_option"] : styles["odd_option"]
                }
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ColoredSelect;
