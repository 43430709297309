import React, { useContext } from "react";
import DashboardContainer from "../../DashboardContainer";
import NationalityCard from "./NationalityCard";
import styles from "./NationalityCard.module.css";
import { useFetchNationalities } from "../../../../../Hooks/Dashboard";
import Loader from "../../../Loader/Loader";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import NoDataDashboard from "../../NoData/NoDataDashboard";

const Nationalities = () => {
  const { date, applicationStart } = useContext(ApplicationContext);
  const { data: nationalityData, isLoading } = useFetchNationalities({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const processedData =
    nationalityData?.data?.map((item) => ({
      name: item.label,
      number: item.value,
    })) || [];

  return (
    <DashboardContainer none>
      <DashboardContainer title='Most Nationality Applied' />
      {isLoading ? (
        <Loader />
      ) : processedData?.length === 0 ? (
        <NoDataDashboard background />
      ) : (
        <div className={styles["grid"]}>
          {processedData?.slice(0, 10)?.map((item) => {
            return (
              <div key={item.name}>
                <NationalityCard data={item} />
              </div>
            );
          })}
        </div>
      )}
    </DashboardContainer>
  );
};

export default Nationalities;
