import React, { useState } from "react";
import styles from "./PopOver.module.css";

const PopOver = ({ info, scroll, stop = false }) => {
  const [active, setActive] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  console.log("porfkrefcbf", stop);

  const handleOnClick = (index, handleFunction) => (e) => {
    if (stop) {
      setActiveIndex(index);
      if (index !== activeIndex) {
        handleFunction(e);
      }

      setTimeout(() => {
        setActiveIndex(null);
      }, 5000);
    } else {
      handleFunction(e);
    }
  };

  return (
    <div className={`${scroll ? styles["container"] : ""} `}>
      <div className={`${styles["popOver-container"]} `}>
        {info?.map((item, index) => {
          const subContainerClassName = `${styles["popOver-subContainer"]} ${
            index % 2 === 0 ? "" : styles["popOver-subContainer-even"]
          }  ${item.active ? styles["active"] : ""}`;
          const isActive = index === activeIndex;

          return (
            <div
              key={index}
              className={`${subContainerClassName} ${
                isActive ? styles.inActiveDiv : ""
              }`}
              onClick={
                item.handleFunction
                  ? handleOnClick(index, item.handleFunction)
                  : null
              }
            >
              <img src={item.icon} alt={item.icon} />
              <h4>{item.title}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopOver;
