import React from "react";
import styles from "./onlineForm.module.css";
import attachment from "../../../../assets/Imagess/attachmentIcon.svg";
import availableIcon from "../../../../assets/Imagess/availableICon.svg";
import UnavailableIcon from "../../../../assets/Imagess/notAvailableIcon.svg";
import FilledUnFilledText from "../../../Atoms/CustomizedAtoms/FilledUnFilledText";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
const StudentInfo = ({ applicant, applicationData, selectedApplication }) => {

  const checkVisa = (value) => {
    return value ? "Yes" : "No";
  };

  const colleges = [
    {
      name: applicationData[0]?.collegeUniversity1,
      years: applicationData[0]?.yearsAttended1,
    },
    {
      name: applicationData[0]?.collegeUniversity2,
      years: applicationData[0]?.yearsAttended2,
    },
    {
      name: applicationData[0]?.collegeUniversity3,
      years: applicationData[0]?.yearsAttended3,
    },
  ];
 
  const renderColleges = () => {
    return colleges?.map((college, index) => {
      if (college.name && college.years !== null) {
        console.log('cdsjcnkjsd',college.name,"  ......",college.years)
        return (
          <>
            <TextContainer
              title={`College/University ${index + 1}`}
              data={college.name}
            />{" "}
            <TextContainer
              title={`Years Attended ${index + 1}`}
              data={college.years}
            />
          </>
        );
      }
      return ""
    });
  };
  return (
    <div className={styles["personal-container"]}>
      <h3>Student Info</h3>
      <div className={styles["personalsub-container"]}>
        <div>
          <TextContainer
            title='Residence Visa'
            data={checkVisa(applicationData[0]?.residenceVisa)}
          />
          <TextContainer
            title='Housing Required'
            data={checkVisa(applicationData[0]?.housingRequired)}
          />
          {renderColleges()}
        </div>
      </div>
    </div>
  );  
};

export default StudentInfo;
