import React, { useContext } from "react";
import styles from "./DashboardCategories.module.css";
import DashboardContainer from "../../DashboardContainer";
import WhiteBoxContainer from "../../../../HOC/WrappingContainer/WhiteBoxContainer";
import archIcon from "../../../../../assets/Imagess/Dashboard/archICon.svg";
import businessIcon from "../../../../../assets/Imagess/Dashboard/businessIcon.svg";
import communicationIcon from "../../../../../assets/Imagess/Dashboard/communicationIcon.svg";
import leadershipIcon from "../../../../../assets/Imagess/Dashboard/leadershipIcon.svg";
import managementIcon from "../../../../../assets/Imagess/Dashboard/managementIcon.svg";
import progressionIcon from "../../../../../assets/Imagess/Dashboard/progressionIcon.svg";
import { useFetchMostFieldOfInterest } from "../../../../../Hooks/Dashboard";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import NoDataDashboard from "../../NoData/NoDataDashboard";
import Loader from "../../../Loader/Loader";

const DashboardCategories = () => {
  const { date, setDate, applicationStart } = useContext(ApplicationContext);
  const { data: mostFieldsData, isLoading } = useFetchMostFieldOfInterest({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const fieldsData = mostFieldsData?.data || [];

  const iconMapping = {
    Accounting: businessIcon,
    "Master of Arts in International Affairs": archIcon,
    MBA: leadershipIcon,
    "Masters in Marketing": communicationIcon,
  };

  const data = fieldsData.map((field) => ({
    title: field.label,
    number: field.value,
    icon: iconMapping[field.label] || managementIcon,
  }));
  console.log("vknrvrejvn",fieldsData)
  return (
    <DashboardContainer none>
      <DashboardContainer title='Most Field of Interest Applied' />
      {isLoading ? (
        <Loader />
      ) : fieldsData?.length === 0 ? (
        <NoDataDashboard background />
      ) : (
        <div className={styles["gridTwo"]}>
          {data?.map((item) => {
            return (
              <WhiteBoxContainer center key={item.title}>
                <div className={styles["iconContainer"]}>
                  <img src={item.icon} alt='icon' />
                  <div className={styles["titleContainer"]}>
                    <h3>{item.title}</h3>
                    <h5>{item.number}</h5>
                  </div>
                </div>
              </WhiteBoxContainer>
            );
          })}
        </div>
      )}
    </DashboardContainer>
  );
};

export default DashboardCategories;
