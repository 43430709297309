import React, { useState } from "react";
import styles from "../Users/UsersTable.module.css";
import deleteIcon from "../../../assets/Imagess/deleteicon.svg";
import editIcon from "../../../assets/Imagess/Edit (1).svg";
import Loader from "../Loader/Loader";
import Toggle from "../../Atoms/Inputs/Toggle";
import { useChangeEmailStatus } from "../../../Hooks/Notification";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import { useLocation } from "react-router-dom";
import NoDataComponent from "../Custom/NoData/NoDataComponent";
import TablePagination from "../Pagination/TablePagination";
import { toast } from "react-toastify";

const EmailTable = ({
  data,
  setShowDeleteModal,
  setSelectedEmail,
  handleEdit,
  additionalIcon,
  isEmailLoading,
  refetchEmails,
  setOrderBy,
  setOrderDir,
  orderDir,
  orderBy,
  isCampaign = false,
  pagination,
  pageSize,
  setPageSize,
  page,
  setPage,
}) => {
  const [isActiveStatus, setIsActiveStatus] = useState(false);
  const [rotatedColumn, setRotatedColumn] = useState(null);
  const [loadingToggleIndex, setLoadingToggleIndex] = useState(null);
  const location = useLocation();
  const changeEmailStatus = useChangeEmailStatus();
  const itemsPerPage = data?.pageSize;
  const totalItems = data?.totalCount;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const headerMap = {
    notificationName: "Name",
    ...(!isCampaign ? { notificationType_Display: "Email Type" } : {}), 
    hasAttachment: "Attachment",
    userCreated: "Created By",
    isActive: "Status",
    actions: "",
  };

  if (isEmailLoading) {
    return <Loader />;
  }

  const orderByFunction = (item, index) => {
    setTimeout(() => {
      const nameToUpperCase = item[0].toUpperCase() + item.slice(1);
      setOrderBy(nameToUpperCase);
      setRotatedColumn(item);
      const newOrderDir = orderDir === "asc" ? "desc" : "asc";
      setOrderDir(newOrderDir);

      data.refetch({
        pageNumber: data.page,
        orderDir: newOrderDir,
        orderBy: nameToUpperCase,
      });
    }, 500);
  };

  const handleToggleChange = async (index) => {
    setLoadingToggleIndex(index);
    const updatedData = [...data?.items];
    updatedData[index].isActive = !updatedData[index].isActive;

    try {
      await changeEmailStatus.mutate(
        {
          NotificationId: updatedData[index].notificationId,
          isActive: updatedData[index].isActive,
        },
        {
          onSuccess: () => {
            refetchEmails();
            setLoadingToggleIndex(null);
            toast.success("Changed Successfully")
          },
          onError: () => {
            setLoadingToggleIndex(null);
          },
        }
      );
    } catch (error) {
      console.error("Error occurred while changing email status:", error);
      setLoadingToggleIndex(null);
    }
  };

  return (
    <>
      <div className={styles["container"]}>
        <table className={styles["userstable-container"]}>
          <thead>
            <tr>
              {Object.entries(headerMap).map(([key, value]) => (
                <th key={key} className={styles["header-cell"]}>
                  {value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.items?.length > 0 ? (
              <>
                {data?.items?.map((item, index) => {
                  const isButtonDisabled =
                    loadingToggleIndex !== null && loadingToggleIndex !== index;
                  return (
                    <tr key={item.notificationId}>
                      <td>{item.notificationName || "-"}</td>
                      {!isCampaign && (
                        <td>{item.notificationType_Display || "-"}</td> 
                      )}
                      <td>{item.hasAttachment || "-"}</td>
                      <td>{item.userCreated || "-"}</td>
                      <td>
                        <Toggle
                          isActive={item.isActive}
                          onChange={() => handleToggleChange(index)}
                          disabled={isButtonDisabled}
                        />
                      </td>
                      <td className={styles["icon-cont-table"]}>
                        {additionalIcon ? additionalIcon(item) : ""}
                        <Tooltip content='Edit'>
                          <img
                            src={editIcon}
                            alt='icon'
                            onClick={() => handleEdit(item)}
                            disabled={isButtonDisabled}
                          />
                        </Tooltip>
                        <Tooltip content='Delete' red>
                          <img
                            src={deleteIcon}
                            alt='icon'
                            onClick={() => (
                              setShowDeleteModal(true), setSelectedEmail(item)
                            )}
                            disabled={isButtonDisabled}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={Object.keys(headerMap).length}>
                  <NoDataComponent />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && data?.items?.length > 0 ? (
        <TablePagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          itemsPerPage={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default EmailTable;
