import React, { useEffect } from "react";
import styles from "./onlineForm.module.css";
import Loader from "../../Loader/Loader";
import PersonalInformation from "./PersonalInformation";
import MailingInfo from "./MailingInfo";
import ProgramInformation from "./ProgramInformation";
import ParentOfGuardian from "./ParentOfGuardian";
import Consent from "./Consent";
import AcademicInfo from "./AcademicInfo";
import ListOfAcademicRecords from "./ListOfAcademicRecords";
import AcademicTest from "./AcademicTest";
import PersonalStatement from "./PersonalStatement";
import WorkExperience from "./WorkExperience";
import ReferenceInformation from "./ReferenceInformation";
import TranscriptMailingAddress from "./TranscriptMailingAddress";
import Declaration from "./Declaration";
import StudentInfo from "./StudentInfo";
const OnlineForm = ({
  applicant,
  isApplicant,
  applicationData,
  isLoading,
  selectedApplication,
}) => {
  return (
    <div className={styles["onlinform-container"]}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className={styles["sub-container"]}
            style={{ gridTemplateColumns: isApplicant ? "" : "1fr" }}
          >
            <PersonalInformation
              applicant={applicant}
              isApplicant={isApplicant}
            />
            {isApplicant && (
              <MailingInfo
                applicant={applicant}
                applicationData={applicationData}
                selectedApplication={selectedApplication}
              />
            )}
          </div>
          {isApplicant && (
            <>
              {" "}
              <div className={styles["sub-container"]}>
                <ProgramInformation
                  applicant={applicant}
                  applicationData={applicationData}
                  selectedApplication={selectedApplication}
                />
                <ParentOfGuardian
                  applicant={applicant}
                  applicationData={applicationData}
                  selectedApplication={selectedApplication}
                />
              </div>
              <div className={styles["sub-container"]}>
                <Consent applicationData={applicationData} />
                {selectedApplication?.applyingAs !== 2 ? (
                  <AcademicInfo
                    applicationData={applicationData}
                    applicant={applicant}
                  />
                ) : (
                  ""
                )}
              </div>
              {selectedApplication?.applyingAs !== 2 ? (
                <ListOfAcademicRecords
                  applicationData={applicationData}
                  applicant={applicant}
                />
              ) : (
                ""
              )}
              <div className={styles["sub-container"]}>
                <PersonalStatement applicationData={applicationData} />

                {/* {selectedApplication?.applyingAs !== 0 &&
                  selectedApplication?.applyingAs !== 7 ? (
                    <PersonalStatement applicationData={applicationData} />
                  ) : (
                    ""
                  )} */}
                {selectedApplication?.applyingAs !== 2 ? (
                  <AcademicTest applicationData={applicationData} />
                ) : (
                  ""
                )}
              </div>
            </>
          )}
          {isApplicant ? (
            <>
              {selectedApplication?.applyingAs !== 2 ? (
                <>
                  <div className={styles["sub-container"]}>
                    {selectedApplication?.startYourApplication == 1 ? (
                      <WorkExperience applicationData={applicationData} />
                    ) : (
                      ""
                    )}
                    {selectedApplication?.startYourApplication == 1 ||
                    selectedApplication?.applyingAs == 8 ? (
                      <ReferenceInformation
                        applicationData={applicationData}
                        selectedApplication={selectedApplication}
                        applicant={applicant}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={styles["sub-container"]}>
                    {selectedApplication?.applyingAs == 6 ||
                    selectedApplication?.applyingAs == 8 ? (
                      <TranscriptMailingAddress
                        applicationData={applicationData}
                      />
                    ) : (
                      ""
                    )}
                    <Declaration applicationData={applicationData} />
                  </div>

                  {applicationData?.applicantReApplication &&
                    applicationData?.applicantReApplication?.length > 0 && (
                      <div className={styles["sub-container"]}>
                        <StudentInfo
                          applicationData={
                            applicationData?.applicantReApplication
                          }
                        />
                      </div>
                    )}
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default OnlineForm;
