import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";
import { usePostTableData } from "./services/postCall";

/*****************Fetch type by id */
const fetchSettingsPages = async (data) => {
  return await axios.get(API_URL + `/api/Setting/GetSettingPages`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useFetchSettingsPages = (data) => {
  return useQuery({
    queryKey: ["settingsPages", data],
    queryFn: () => fetchSettingsPages(data),
    enabled: true,
  });
};
/*****************Fetch type by id */
const fetchDropdownTypes = async (data) => {
  let url = `${API_URL}/api/Setting/GetDrowDown/${data?.typeId}`;
  const queryParams = [];
  if (data?.orderBy) queryParams.push(`OrderBy=${data?.orderBy}`);
  if (data?.orderDir) queryParams.push(`orderDir=${data.orderDir}`);
  if (data?.search) queryParams.push(`SearchBy=${data.search}`);

  if (data?.typeId || data?.typeId == 0) {
    if (data?.typeId != 19 && data?.typeId != 20) {
      return await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }
};

export const useFetchDropDownTypes = (data) => {
  return useQuery({
    queryKey: ["Types", data],
    queryFn: () => fetchDropdownTypes(data),
    enabled: true,
  });
};

/*********************Fetch types with paginations */

export const useFetchDropDownTypesWithPagination = (data) => {
  const shouldFetchData = !!(
    (data?.typeId || data?.typeId === 0) &&
    data?.typeId !== 19 &&
    data?.typeId !== 20
  );

  return usePostTableData(
    data,
    `/api/Setting/GetPagedDrowDown/${data?.typeId}`,
    "TypesWithPagination",
    shouldFetchData
  );
};
/*********************Fetch all types */
const fetchAllTypes = async () => {
  return await axios.get(API_URL + "/api/Setting/GetDrowDownTypes", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchAllTypes = () => {
  return useQuery({
    queryKey: ["AllTypes"],
    queryFn: () => fetchAllTypes(),
    enabled: true,
  });
};

/*************************Fetch according to type and  parent */

export const useFetchRelatedToType = (data) => {
  const shouldFetchData = !!(data?.parentId !=="");

  return usePostTableData(
    data,
    `/api/Setting/GetDrowDownFromParent/${data?.type}/${data?.parentId}`,
    "RelatedTypes",
    shouldFetchData
  );
};

/*****************Add Types */
const addValuesForTypes = ({
  typeID,
  value,
  text,
  parent,
  brochureLink,
  applicationsCS,
}) => {
  // const encodedTypeName = encodeURIComponent(value);
  let url = `${API_URL}/api/DropDown/SetDropDown/${typeID}/${value}/${text}`;
  const queryParams = [];
  if (parent) queryParams.push(`parentId=${parent}`);
  if (brochureLink) queryParams.push(`BrochureLink=${brochureLink}`);
  if (applicationsCS) queryParams.push(`ApplicationsCS=${applicationsCS}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }
  return axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useAddValuesForType = () => {
  return useMutation(addValuesForTypes);
};

/********************Get Field Of Interest */
const fetchFieldOfInterest = async () => {
  return await axios.get(API_URL + "/api/Setting/GetFieldOfInterest", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const UseFetchFieldOfInterest = () => {
  return useQuery({
    queryKey: ["fieldOfInterest"],
    queryFn: () => fetchFieldOfInterest(),
    enabled: true,
  });
};
/********************Get Field Of Interest by application start*/
const fetchFieldOfInterestByApplicationStart = async (applicationStart) => {
  if (applicationStart !== undefined) {
    return await axios.get(
      API_URL +
        `/api/Setting/GetFieldOfInterestByApplicationStart/${applicationStart}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};
export const useFetchFieldOfInterestByAppStart = (applicationStart) => {
  return useQuery({
    queryKey: ["fieldOfInterestByApplicationStart", applicationStart],
    queryFn: () => fetchFieldOfInterestByApplicationStart(applicationStart),
    enabled: true,
  });
};
/************Add Term */
const addTerm = (values) => {
  return axios.post(API_URL + `/api/Term/PostAcademicTerms`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddTerm = () => {
  return useMutation(addTerm);
};

/***********Get all  Terms */
export const useFetchTerms = (data) => {
  const shouldFetchData = !!(data.type == 19);
  return usePostTableData(
    data,
    `/api/Setting/GetAcademicTerms`,
    "allTerms",
    shouldFetchData
  );
};

/***********Get academic years */

export const useFetchAcademicYears = (data) => {
  const shouldFetchData = !!(data.type == 20);
  return usePostTableData(
    data,
    `/api/Setting/GetAcadmicYears`,
    "academicYears",
    shouldFetchData
  );
};

/********Delete Term */
const deleteTerm = (termId) => {
  return axios.delete(API_URL + `/api/Term/DeleteAcademicTerm/${termId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useDeleteTerm = () => {
  return useMutation(deleteTerm);
};

/**********Edit Dropdown */
const editValueForTypes = ({ typeID, text, brochureLink, applicationsCS }) => {
  const brochureLinkParam =
    brochureLink !== undefined ? `BrochureLink=${brochureLink}` : "";
  const applicationsCSParam =
    applicationsCS !== undefined ? `ApplicationsCS=${applicationsCS}` : "";

  return axios.post(
    API_URL +
      `/api/DropDown/EditDropDown/${typeID}/${text}?${brochureLinkParam}&${applicationsCSParam}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useEditValuesForTypes = () => {
  return useMutation(editValueForTypes);
};

///delete settings option
const deleteSettingsOption = (settingsId) => {
  return axios.delete(API_URL + `/api/DropDown/DeleteDropDown/${settingsId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useDeleteSettingsOption = () => {
  return useMutation(deleteSettingsOption);
};

////export dropdown options
const exportDropDownOptionsTable = async (type, typeName) => {
  const encodedTypeName = encodeURIComponent(typeName);
  return await axios.get(
    API_URL + `/api/Export/ExportDropDownOptions/${type}/${encodedTypeName}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};
export const useExportDropDownOptionsTable = (type, typeName) => {
  return useMutation(() => exportDropDownOptionsTable(type, typeName));
};

////export terms options
const exportTermsOptions = async () => {
  return await axios.get(API_URL + `/api/Export/ExportTerms`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportTrmsOptions = () => {
  return useMutation(() => exportTermsOptions());
};

////export academic years  options
const exportAcademicYearOptions = async () => {
  return await axios.get(API_URL + `/api/Export/ExportAcademicYears`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportAcademicYearsOptions = () => {
  return useMutation(() => exportAcademicYearOptions());
};

//post academic year
const addAcademicYear = (values) => {
  return axios.post(API_URL + `/api/Term/PostAcademicYears`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddAcademicYear = () => {
  return useMutation(addAcademicYear);
};
///delete academic year
const deleteAcademicYear = (yearId) => {
  return axios.delete(API_URL + `/api/Term/DeleteAcademicYear/${yearId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useDeleteAcademicYear = () => {
  return useMutation(deleteAcademicYear);
};
