import React, { useEffect, useRef, useState } from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import styles from "../../../Template/SettingsTemplate/SettingsPage.module.css";
import uploadbulk from "../../../../assets/Imagess/bulkicon.svg";
import DeletePopup from "../../Custom/DeletePopup";
import SettingsTopHeader from "../SettingsMolecules/SettingsTopHeader";
import ColoredButton from "../../../Atoms/Buttons/ColoredButtons/ColoredButton";
import TitlePageText from "../../../Atoms/Texts/TitlePageText";
import * as tableHeader from "../../TableHeader/SettingsHeader";

import {
  useDeleteAcademicYear,
  useFetchAcademicYears,
} from "../../../../Hooks/Settings";
import { toast } from "react-toastify";
import AcademicYearsModal from "../SettingsModal/AcademicYearsModal";
import TableComponent from "../../TableComponent/TableComponent";
import {
  useDeleteEmailSignature,
  useFetchESignature,
} from "../../../../Hooks/ESignature";
import ESognatureModal from "../SettingsModal/ESognatureModal";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../../Custom/ExportComponent/PrintComponent";
const ESignature = ({ title, type, search, setSearch }) => {
  const printRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [deletMode, setDeleteMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("desc");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    data: eSignature,
    refetch: refetchESignature,
    isLoading: isEsignatureLoading,
  } = useFetchESignature({
    pageSize: pageSize,
    pageNumber: page,
    searchBy: search,
    desc: orderDir == "desc" ? false : true,
    sortBy: orderBy,
  });
  const { mutate: deleteEsignature, isLoading: isDeletingEsignature } =
    useDeleteEmailSignature();

  const handleAddClick = () => {
    setIsModalOpen(true);
    setModalMode("add");
  };

  const handleEditClick = (e, item) => {
    e.stopPropagation();
    setIsModalOpen(true);
    setModalMode("edit");
    setSelectedItem(item);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem({});
  };

  const handleDelete = () => {
    deleteEsignature(selectedItem?.emailSignatureId, {
      onSuccess: () => {
        setDeleteMode(false);
        refetchESignature();
        toast.success("Deleted successfully");
        setSelectedItem({});
      },
      onError: (error) => {
        console.error("Error deleting email", error);
        toast.error("error");
        setDeleteMode(false);
        setSelectedItem({});
      },
    });
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  useEffect(() => {
    refetchESignature({
      orderBy: orderBy,
      orderDir: orderDir,
      search: search,
    });
  }, [orderBy, orderDir]);

  const tableData = {
    data: {
      ...eSignature?.data,
      items: eSignature?.data?.items?.map((item) => ({
        ...item,
        id: item.emailSignatureId,
      })),
    },
    refetch: refetchESignature,
    isLoading: isEsignatureLoading,
    tableHeader: tableHeader.eSignatureHeader,
  };

  return (
    <>
      <SettingsTopHeader
        title={title === "External Contact" ? "New Contact" : title}
        type={type}
        search={search}
        setSearch={setSearch}
        data={tableData}
        handleAddClick={handleAddClick}
        handlePrint={handlePrint}
      />

      <TableComponent
        tableData={tableData}
        handleEditClick={handleEditClick}
        handleDelete={(e, item) => (
          setDeleteMode(true), setSelectedItem(item), e.stopPropagation()
        )}
        orderBy={orderBy}
        orderDir={orderDir}
        setOrderBy={setOrderBy}
        setOrderDir={setOrderDir}
        editAdd={true}
        isOrder
        pagination
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
    
      />

      {isModalOpen ? (
        <ESognatureModal
          handleOnClose={handleCloseModal}
          type={type}
          title={title}
          mode={modalMode}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          refetch={refetchESignature}
        />
      ) : (
        ""
      )}
      {deletMode && (
        <DeletePopup
          handleOnClose={() => (setDeleteMode(false), setSelectedItem({}))}
          title={title}
          handleDelete={handleDelete}
          isLoading={isDeletingEsignature}
        />
      )}
      <div style={{ display: "none" }}>
        <PrintComponent ref={printRef} title={title} data={tableData} />
      </div>
    </>
  );
};

export default ESignature;
