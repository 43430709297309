import React, { useState } from "react";
import styles from "./Notes.module.css";
import USerProfile from "../../../../assets/Imagess/userProfileApplicantDetails.svg";
import QuestionInput from "../../../Atoms/Inputs/QuestionInput";
import { useAddNotes, useDeleteNote } from "../../../../Hooks/SingleApplicant";
import MoreButton from "../../Custom/PopOver/MoreButton";
import moreIcon from "../../../../assets/Imagess/more-vet-svgrepo-com 1.svg";
import deleteicon from "../../../../assets/Imagess/deleteicon.svg";
import editIcon from "../../../../assets/Imagess/Edit (1).svg";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import moment from "moment";
import Loader from "../../Loader/Loader";
import DeletePopup from "../../Custom/DeletePopup";
import { toast } from "react-toastify";
import EmptyText from "../../Custom/EmptyText";
import PopOver from "../../Custom/PopOver/PopOver";

const ProspectLeadNotes = ({ id, refetch, applicantLeadNotes }) => {
  const [note, setNote] = useState("");
  const [edittedNote, setEdittedNote] = useState("");
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [fullName, setFullName] = useState(localStorage.getItem("fullName"));

  const { mutate: addNote, isLoading: isNoteLoading } = useAddNotes();
  const { mutate: deleteNote, isLoading: isLoadingDeleteNote } =
    useDeleteNote();

  const [editedNoteId, setEditedNoteId] = useState("");
console.log("bxdevcre",id)
  const handleOnSubmit = () => {
    if (!note) {
      toast.error("Please write note");
    } else {
      addNote(
        {
          applicantId: id,
          notes: note,
          applicantNoteId: "00000000-0000-0000-0000-000000000000",
        },
        {
          onSuccess: () => {
            refetch();
            setNote("");
            toast.success("Note is added successfully");
          },
        }
      );
    }
  };

  const handleDelete = () => {
    deleteNote(selectedItem.applicantNoteId, {
      onSuccess: () => {
        refetch();
        setDeleteMode(false);
        setSelectedItem({});
        toast.success("Note is deleted successfully");
      },
    });
  };

  const handleEdit = () => {
    addNote(
      {
        applicantId: id,
        notes: edittedNote,
        applicantNoteId: editedNoteId,
      },
      {
        onSuccess: () => {
          refetch();
          setNote("");
          setEditedNoteId("");
          toast.success("Note is editted successfully");
        },
      }
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (editedNoteId) {
        handleEdit();
      } else {
        handleOnSubmit();
      }
    }
  };

 
  return (
    <>
      <div className={styles["Notes-container"]}>
        <div className={styles["notes-details"]}>
          <h2>Lead Notes</h2>
          {/* <CustomizedSelect noborder={true} placeholder='Most Recent' /> */}
        </div>

        {applicantLeadNotes?.length > 0 ? (
          <>
            {applicantLeadNotes?.map((item) => {
              return (
                <div
                  className={styles["Notes-subcontainer"]}
                  key={item.applicantNoteId}
                >
                  <div className={styles["notes-details"]}>
                    <div className={styles["subcontainer"]}>
                      <img src={USerProfile} alt='userPRofile' />
                      <div className={styles["notes-title-cont"]}>
                        <h3>{item?.fullName}</h3>
                        <h4>
                          {moment(item.dateCreated).format(
                            "MMM D, YYYY h:mm A"
                          )}
                        </h4>
                      </div>
                    </div>
                    {/* {fullName === item?.fullName ? (
                      <MoreButton
                        icon={moreIcon}
                        profileImg='profile-img'
                        width='200px'
                        imgWidth='25px'
                        imgHeight='20px'
                        top='1rem'
                        toolTipText="More"
                      >
                        <PopOver
                          info={[
                            {
                              title: "Edit",
                              icon: editIcon,
                              handleFunction: () => (
                                setEditedNoteId(item.applicantNoteId),
                                setEdittedNote(item.notes)
                              ),
                            },
                            {
                              title: "Delete",
                              icon: deleteicon,
                              handleFunction: () => (
                                setDeleteMode(true), setSelectedItem(item)
                              ),
                            },
                          ]}
                        />
                      </MoreButton>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <p>{item.notes}</p>

{/* 
                  {editedNoteId === item.applicantNoteId ? (
                    <>
                      {" "}
                      {isNoteLoading ? (
                        <Loader />
                      ) : (
                        <QuestionInput
                          value={edittedNote}
                          onChange={(e) => setEdittedNote(e.target.value)}
                          onkeyDown={handleKeyDown}
                          handleSubmit={handleEdit}
                          placeholder='Type your notes'
                          onClose={() => setEditedNoteId("")}
                          edit={editedNoteId.length > 0 ? true : false}
                        />
                      )}
                    </>
                  ) : (
                    <p>{item.notes}</p>
                  )} */}
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className={styles["Notes-subcontainer"]}>
              <div className={styles["subcontainer"]}>
                <EmptyText text='Notes' />
              </div>
            </div>
          </>
        )}
        {/* {editedNoteId.length > 0 ? (
          ""
        ) : (
          <>
            {" "}
            {isNoteLoading ? (
              <Loader />
            ) : (
              <QuestionInput
                onkeyDown={handleKeyDown}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                handleSubmit={() => handleOnSubmit()}
                placeholder='Type your notes'
              />
            )}
          </>
        )} */}
      </div>

      {deleteMode && (
        <DeletePopup
          handleOnClose={() => setDeleteMode(false)}
          title='Note'
          handleDelete={handleDelete}
          isLoading={isLoadingDeleteNote}
        />
      )}
    </>
  );
};

export default ProspectLeadNotes;
